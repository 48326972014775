import React, { CSSProperties } from "react";

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  alignment?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  padding?: string;
  children: React.ReactNode;
}

const Row: React.FC<RowProps> = ({
  alignment = "center",
  padding = "var(--small-spacing)",
  style,
  children,
  ...rest
}) => {
  const rowStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: alignment,
    padding,
    ...style,
  };

  return (
    <div style={rowStyle} {...rest}>
      {children}
    </div>
  );
};

export default Row;
