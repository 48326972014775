// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";

import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./contexts/TeamsFxContext";
import teamsClientConfig from "./configs/teams.client.config";
import MainRouter from "./routers/MainRouter";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const {
    loading,
    theme: userTheme,
    themeString,
    teamsUserCredential,
  } = useTeamsUserCredential({
    initiateLoginEndpoint: teamsClientConfig.initiateLoginEndpoint!,
    clientId: teamsClientConfig.clientId!,
  });

  const fluentTheme =
    themeString === "dark"
      ? teamsDarkTheme
      : themeString === "contrast"
      ? teamsHighContrastTheme
      : {
          ...teamsLightTheme,
          colorNeutralBackground3: "#eeeeee",
        };

  const appTheme = { ...fluentTheme, ...userTheme };

  return (
    <div id="root">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </head>
      <TeamsFxContext.Provider
        value={{ theme: appTheme, themeString, teamsUserCredential }}
      >
        <FluentProvider
          theme={fluentTheme}
          style={{
            background: tokens.colorNeutralBackground3,
          }}
        >
          <MainRouter loading={loading} />
        </FluentProvider>
      </TeamsFxContext.Provider>
    </div>
  );
}
