import React from "react";

interface SpacerProps {
  direction: "x" | "y";
  size: "xs" | "sm" | "md" | "lg";
}

const Spacer: React.FC<SpacerProps> = ({ direction, size }) => {
  const getSpacing = (size: "xs" | "sm" | "md" | "lg"): string => {
    switch (size) {
      case "xs":
        return "var(--very-small-spacing)";
        break;
      case "sm":
        return "var(--small-spacing)";
      case "md":
        return "var(--medium-spacing)";
      case "lg":
        return "var(--big-spacing)";
      default:
        return "var(--medium-spacing)";
    }
  };

  const spacerStyle: React.CSSProperties = {
    width: direction === "x" ? getSpacing(size) : "0",
    height: direction === "y" ? getSpacing(size) : "0",
  };

  return <div style={spacerStyle} />;
};

export default Spacer;
