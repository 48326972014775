import { useContext, useEffect } from "react";
import "./Chat.css"; // Import the CSS file
import { TeamsFxContext } from "../../../contexts/TeamsFxContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//const RWC = window.richWebChat.Core;

interface ChatProps {
  userEmail: string;
}

function Chat({ userEmail }: ChatProps) {
  const { themeString } = useContext(TeamsFxContext);
  let theme;

  switch (themeString) {
    case "default":
      theme = "light";
      break;
    case "dark":
      theme = "dark";
      break;
    default:
      theme = "light";
  }

  return (
    <iframe
      name="chat"
      className="chat-iframe"
      src={`https://chat.orsolaas.onereach.ai/Odscxk-ARUOnLtx1sUvBwQ/0b8a0gu?loader=${theme}&usermail=${userEmail}`}
    ></iframe>
  );
}

export default Chat;
