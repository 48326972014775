import { TicketState } from "../../../../entities/tickets/types";
import {
  CheckmarkCircle24Regular,
  LockClosed24Regular,
  DismissCircle24Regular,
  Open24Regular,
  QuestionCircle24Regular,
} from "@fluentui/react-icons";

interface StateResult {
  state: string;
  icon: JSX.Element;
  color: string;
}

export default function getStateWithIcon(
  ticketState: TicketState
): StateResult {
  let stateLabel: string;
  let icon: JSX.Element;
  let color: string;

  switch (ticketState) {
    case "ASSIGNED":
      stateLabel = "Assegnato";
      icon = <CheckmarkCircle24Regular style={{ color: "green" }} />;
      color = "green";
      break;
    case "CLOSED":
      stateLabel = "Chiuso";
      icon = <LockClosed24Regular style={{ color: "blue" }} />;
      color = "blue";
      break;
    case "CANCELLED":
      stateLabel = "Annullato";
      icon = <DismissCircle24Regular style={{ color: "red" }} />;
      color = "red";
      break;
    case "OPENED":
      stateLabel = "Aperto";
      icon = <Open24Regular style={{ color: "orange" }} />;
      color = "orange";
      break;
    default:
      stateLabel = "Sconosciuto";
      icon = <QuestionCircle24Regular style={{ color: "grey" }} />;
      color = "grey";
      break;
  }

  return {
    state: stateLabel,
    icon,
    color,
  };
}
