import { useContext, useEffect, useRef, useState } from "react";
import {
  TabList,
  Tab,
  SelectTabEvent,
  SelectTabData,
  TabValue,
  Text,
} from "@fluentui/react-components";
import "./Main.css";
import { useData } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "../../../contexts/TeamsFxContext";
import Chat from "../../tabs/chat/Chat";
import ThemeProvider from "../../../contexts/ThemeProvider";
import { devLog } from "../../../utils/log";
import { ChatRegular, ListBarFilled } from "@fluentui/react-icons";
import Ticket from "../../tabs/ticket/Ticket";
import Container from "../../ui/layouts/container";
import Row from "../../ui/layouts/row";
import Column from "../../ui/layouts/column";
import Filler from "../../ui/layouts/filler";
import { app } from "@microsoft/teams-js";

export function MainScreen(props: {
  showFunction?: boolean;
  environment?: string;
}) {
  const [selectedValue, setSelectedValue] = useState<TabValue>("chat");
  const [tabHeaderHeight, setTabHeaderHeight] = useState(0);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const { teamsUserCredential, theme } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    const init = await app.initialize();
    console.log(`App initialized: ${app.isInitialized()}`);
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      console.log(`User info: ${JSON.stringify(userInfo)}`);

      return userInfo;
    }
  });
  const userName = loading || error ? "" : data!.displayName;

  const tabSelectorRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabSelectorRowRef.current) {
      setTabHeaderHeight(tabSelectorRowRef.current.clientHeight);
    }
  }, []);

  return (
    <ThemeProvider>
      <Container
        alignment="center"
        className="hide-scrollbars"
        padding="0"
        style={{ overflowY: "scroll", flex: 1 }}
      >
        <Row
          padding="var(--small-spacing)"
          style={{
            width: "100%",
            backgroundColor: theme?.colorBrandBackground,
            borderBottom: theme
              ? "1px solid " + theme.colorBrandBackground4Static
              : undefined,
            alignItems: "center",
          }}
          alignment="flex-start"
        >
          <img
            className="logo"
            src={"/logo_skynet-bianco.svg"}
            alt="Skynet Logo"
          />
          <Filler />

          <Column className="header-text-column" padding="0">
            {
              <Text
                size={500}
                style={{ fontWeight: "bold", padding: 0, color: "white" }}
                align="start"
              >
                {userName}
              </Text>
            }
            <Text
              size={userName ? 300 : 500}
              style={{ fontWeight: "bold", padding: 0, color: "white" }}
              align="start"
            >
              Area clienti Skynet
            </Text>
            {false && (
              <Text align="start">
                Benvenuto nell'area personale Skynet.
                <br />
                Contatta il nostro team di supporto con la chat dedicata oppure
                consulta i tuoi ticket.
              </Text>
            )}
          </Column>
          <Filler />
        </Row>

        <div
          ref={tabSelectorRowRef}
          style={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 99,
          }}
        >
          <Row
            padding="0"
            style={{
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: 99,
              backgroundColor: theme?.colorNeutralBackground3 ?? "#eeeeee",
            }}
          >
            <TabList
              className="tablist-header"
              selectedValue={selectedValue}
              onTabSelect={onTabSelect}
              appearance="subtle"
              style={{ position: "-webkit-sticky" }}
            >
              <Tab id="chat" value="chat" icon={<ChatRegular />}>
                Chat
              </Tab>

              <Tab id="ticketList" value="ticket" icon={<ListBarFilled />}>
                I tuoi ticket
              </Tab>
            </TabList>
          </Row>
        </div>
        {selectedValue === "chat" && (
          <Chat userEmail={data?.preferredUserName ?? ""} />
        )}
        {selectedValue === "ticket" && (
          <Ticket tabHeaderHeight={tabHeaderHeight} />
        )}
      </Container>
    </ThemeProvider>
  );
}
