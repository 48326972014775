// https://fluentsite.z22.web.core.windows.net/quick-start
import { Spinner } from "@fluentui/react-components";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { MainScreen } from "../components/screens/main/Main";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function MainRouter({ loading }: { loading: boolean }) {
  return (
    <Router>
      {loading ? (
        <Spinner style={{ margin: 100 }} />
      ) : (
        <Routes>
          <Route path="/" element={<MainScreen showFunction={false} />} />
          <Route path="*" element={<Navigate to={"/"} />}></Route>
        </Routes>
      )}
    </Router>
  );
}
