import { TicketState } from "../../../../entities/tickets/types";
import Row from "../../layouts/row";
import Spacer from "../../layouts/spacer";
import getStateWithIcon from "./helper";

const TicketStateComponent: React.FC<{ state: TicketState }> = ({
  state: ticketState,
}) => {
  const { state, icon, color } = getStateWithIcon(ticketState);

  return (
    <Row style={{ alignItems: "center", color }} padding="0">
      {icon}
      <Spacer direction="x" size="xs" />
      <span>{state}</span>
    </Row>
  );
};
export default TicketStateComponent;
