import "./Ticket.css"; // Import the CSS file
import { useData } from "@microsoft/teamsfx-react";
import { fetchTickets } from "../../../entities/tickets/fetchers";
import { Button, Spinner, Text } from "@fluentui/react-components";
import Container from "../../ui/layouts/container";
import Column from "../../ui/layouts/column";
import Row from "../../ui/layouts/row";
import { TicketDTO } from "../../../entities/tickets/types";
import { ArrowSync24Regular } from "@fluentui/react-icons";
import Spacer from "../../ui/layouts/spacer";
import TicketCard from "../../ui/tickets/card/ticket-card";
import { useContext } from "react";
import { TeamsFxContext } from "../../../contexts/TeamsFxContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error

function TicketList({ tabHeaderHeight }) {
  const { theme } = useContext(TeamsFxContext);
  const { teamsUserCredential } = useContext(TeamsFxContext);

  const {
    loading,
    data: tickets,
    error,
    reload,
  } = useData(async () => {
    let accessToken, tickets;
    try {
      console.log("Getting token for User.Read");
      accessToken = await teamsUserCredential?.getToken("User.Read");
    } catch (error) {
      throw error;
    }
    try {
      console.log("Fetching tickets");
      tickets = await fetchTickets(accessToken?.token);
      tickets = tickets.filter(
        (ticket) => ticket.State === "ASSIGNED" || ticket.State === "OPENED"
      );
      return tickets;
    } catch (error) {
      throw error;
    }
  });

  return (
    <Container alignment="center" padding="0">
      {loading && (
        <Column>
          <Spinner />
        </Column>
      )}
      {!loading && tickets?.length && tickets?.length > 0 && (
        <Column alignment="center" padding="0" style={{ width: "100%" }}>
          <Row
            padding="0"
            style={{
              width: "100vw",
              position: "sticky",
              top: tabHeaderHeight,
              zIndex: 99,
              backgroundColor: theme?.colorNeutralBackground3 ?? "#eeeeee",
            }}
          >
            <Column padding="0">
              <Button onClick={reload}>
                <Text>Aggiorna lista</Text>
                <Spacer direction="x" size="sm" />
                <ArrowSync24Regular />
              </Button>
              <Spacer direction="y" size="sm" />
            </Column>
          </Row>
          <Column
            padding="var(--small-spacing)"
            className="hide-scrollbars"
            style={{ overflowY: "scroll", flex: 1, width: "100%" }}
          >
            {tickets.map((ticket: TicketDTO, idx: number) => (
              <TicketCard ticket={ticket} />
            ))}
          </Column>
        </Column>
      )}
    </Container>
  );
}

export default TicketList;
