import { TicketDTO } from "./types";

//TODO: Change URL with the correct one (username is not needed)
export async function fetchTickets(token?: string): Promise<TicketDTO[]> {
  try {
    const response = await fetch("https://teamsbe.xcall.it/api", {
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
