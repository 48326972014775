import { ReactNode, useContext } from "react";
import { TeamsFxContext } from "./TeamsFxContext";

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const { themeString } = useContext(TeamsFxContext);
  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      {children}
    </div>
  );
}
