import React from "react";

interface ContainerProps {
  alignment?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  padding?: string;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  alignment = "center",
  padding = "var(--medium-spacing)",
  style,
  className,
  children,
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: alignment,
    padding: padding,
    ...style,
  };

  return (
    <div className={className} style={containerStyle}>
      {children}
    </div>
  );
};

export default Container;
