import React from "react";

interface ColumnProps {
  alignment?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  padding?: string;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
}

const Column: React.FC<ColumnProps> = ({
  alignment = "center",
  padding = "1rem",
  style,
  className,
  children,
}) => {
  const columnStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: alignment,
    padding: padding,
    ...style,
  };

  return (
    <div className={className} style={columnStyle}>
      {children}
    </div>
  );
};

export default Column;
