import { Card, CardHeader, CardFooter, Text } from "@fluentui/react-components";
import { TicketDTO, TicketState } from "../../../../entities/tickets/types";
import {
  getCleanSkynetDate,
  translateSkynetTimePeriod,
} from "../../../../utils/generic";
import Column from "../../layouts/column";
import HtmlRenderer from "../../layouts/html";
import Row from "../../layouts/row";
import Spacer from "../../layouts/spacer";
import TicketStateComponent from "../state/state";
import Filler from "../../layouts/filler";
import { useContext, useEffect } from "react";
import { TeamsFxContext } from "../../../../contexts/TeamsFxContext";
import { useData } from "@microsoft/teamsfx-react";

const TicketCard: React.FC<{ ticket: TicketDTO }> = ({ ticket }) => {
  return (
    <Card
      style={{
        maxWidth: "550px",
        width: "100%",
        marginBottom: "var(--small-spacing)",
      }}
    >
      <CardHeader
        header={
          <Column
            alignment="flex-start"
            style={{
              width: "100%",
              flex: 1,
              borderBottom: "solid 0.01rem lightgray",
            }}
          >
            <Row
              alignment="space-between"
              padding="0"
              style={{
                width: "100%",
                flex: 1,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Column alignment="flex-start" padding="0">
                <Text style={{ fontWeight: "bold" }}>
                  #{ticket.TicketNumber}
                </Text>
                <div style={{ marginLeft: -3 }}></div>
              </Column>
              <Spacer direction="x" size="lg" />
              <Filler />
              <Column alignment="flex-end" padding="0" style={{ flexGrow: 1 }}>
                <TicketStateComponent state={ticket.State} />
              </Column>
            </Row>
            <Spacer direction="y" size="sm" />
          </Column>
        }
      />
      {false && ticket.Resolution && (
        <Row alignment="flex-start">
          <Column alignment="flex-start" padding="0">
            <Column alignment="flex-start" padding="0">
              <Spacer direction="y" size="sm" />
              <Text style={{ fontWeight: "bold" }}>Soluzione:</Text>
              <Text>{ticket.Resolution}</Text>
            </Column>
          </Column>
        </Row>
      )}
      <Row alignment="flex-start">
        <Column alignment="flex-start" padding="0">
          <Text style={{ fontWeight: "bold" }}>Aperto da:</Text>
          <Spacer direction="y" size="sm" />
          <Text>{ticket.Email}</Text>
        </Column>
      </Row>
      <Row alignment="flex-start">
        <Column alignment="flex-start" padding="0">
          <Text style={{ fontWeight: "bold" }}>Descrizione:</Text>
          <Spacer direction="y" size="sm" />
          <Text>{ticket.ShortDescription}</Text>
        </Column>
      </Row>
      {false && ticket.DateFromLastUpdate && (
        <CardFooter
          action={
            <Text>
              Ultimo aggiornamento{" "}
              {translateSkynetTimePeriod(ticket.DateFromLastUpdate)} fa
            </Text>
          }
        />
      )}
    </Card>
  );
};
export default TicketCard;
